import React, { Component } from 'react'
import { tada } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import './App.css';

class App extends Component {

  render() {
    const styles = {
    bounce: {
      animation: 'x 3s',
      animationName: Radium.keyframes(tada, 'tada'),
      textAlign: 'center',
      fontSize: 42,
      color: 'white'
    }
  }
    return (
      <StyleRoot>
        <div className ="card">
          <div className="header">
            hristjorn.is
          </div>
          <div className="subHeader" style={styles.bounce}>
            Er í vinnslu
          </div>
        </div>
      </StyleRoot>
    )
  }
}

export default App;
